/* Login.css */
.vh-90.gradient-custom {
  background: url('../Components//bg.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.card-registration {
  background-color: #fffffff2;
  backdrop-filter: blur(10px);
}

.form-outline {
  margin-bottom: 1.5rem;
}

.form-control-lg {
  border-radius: 0.5rem;
}

.select-label {
  margin-top: 0.5rem;
}

.btn-primary {
  background-color: #4e54c8;
  border-color: #4e54c8;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #3c40c6;
  border-color: #3c40c6;
}
.signup-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.card-registration {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
}
