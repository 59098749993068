.mainPanelStyle {
    padding: 80px;
    font-family: Arial, sans-serif;
    margin-top: 110px;
  }
  
  .headerStyle {
    font-size: 28px;
    margin-bottom: 20px;
    color: #2A4B8D;
    text-align: center;
  }
  
  .supportContainerStyle {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .supportFormStyle {
    width: 48%;
  }
  
  .faqStyle {
    width: 48%;
  }
  
  .subHeaderStyle {
    font-size: 24px;
    margin-bottom: 15px;
    color: #2A4B8D;
  }
  
  .formStyle {
    display: flex;
    flex-direction: column;
  }
  
  .labelStyle {
    margin-bottom: 10px;
  }
  
  .inputStyle {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .buttonStyle {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #2A4B8D;
    color: white;
    cursor: pointer;
    font-size: 16px;
  }
  
  .buttonStyle:hover {
    background-color: #1f3870;
  }
  
  .faqItemStyle {
    margin-bottom: 15px;
  }
  
  .faqItemHeaderStyle {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .statusMessage {
    margin-top: 15px;
    font-size: 16px;
    color: #333;
  }
  