.add-benef-comp {
 
  width: 50%;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
}

.add-benef-comp h1 {
  text-align: center;
  margin-bottom: 20px;
}

.add-benef-comp form {
  display: flex;
  flex-direction: column;
}

.add-benef-comp label {
  margin-bottom: 15px;
  font-weight: bold;
}

.add-benef-comp input,
.add-benef-comp select {
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-benef-comp button {
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-benef-comp button:hover {
  background: #0056b3;
}

.alert {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
}

.alert.success {
  background-color: #d4edda;
  color: #155724;
}

.alert.error {
  background-color: #f8d7da;
  color: #721c24;
}
