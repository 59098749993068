.receiver-info {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.receiver-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.receiver-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

.receiver-card,
.beneficiaries-card,
.deposit-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 10px;
  padding: 15px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.receiver-card.expanded,
.beneficiaries-card.expanded,
.deposit-card.expanded {
  background-color: #fcfcfc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: scale(1.02);
}

.card-header {
  display: flex;
  align-items: center;
}

.card-header i {
  font-size: 20px;
  margin-right: 10px;
  color: #007bff;
}

.card-header h3 {
  margin: 0;
  font-size: 18px;
  color: #007bff;
}

.card-body {
  margin-top: 15px;
}

.form-group {
  position: relative;
  margin: 10px 0;
  animation: fadeIn 0.5s ease-in-out;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-group input::placeholder,
.form-group select {
  color: #aaa;
}

.form-group input:focus,
.form-group select:focus {
  border-bottom: 2px solid #007bff;
  outline: none;
}

.or-divider {
  text-align: center;
  margin: 20px 0;
  font-size: 16px;
  color: #555;
}

.beneficiaries-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.beneficiaries-list li {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
}

.continue-button {
  display: block;
  margin-top: 20px;
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.continue-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
